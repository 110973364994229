<template>
  <div>

    <div class="row" v-permission="['group.create','group.edit']">
      <div class="col-md-12">



        <KTPortlet
            v-bind="{
            }"
            v-bind:class="{ 'kt-spinner-f': loading }"
        >
          <template v-slot:title>
            <h4 class="">Configurazione</h4>
          </template>
          <template v-slot:body>
            <!--                        <b-form-group-->
            <!--                                label-cols="4"-->
            <!--                                label-cols-lg="2"-->
            <!--                                label-size="sm"-->
            <!--                                label="Small"-->
            <!--                                label-for="input-sm"-->
            <!--                        >-->
            <!--                            <b-form-input id="input-sm" size="sm"></b-form-input>-->
            <!--                        </b-form-group>-->
            <b-form @submit="onSubmit" @reset="onReset">
              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="Nome"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.name"
                    type="text"
                    required
                    placeholder="Inserisci il Nome"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="Descrizione"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.description"
                    type="text"
                    required
                    placeholder="Inserisci la descrizione"
                ></b-form-input>
              </b-form-group>

              <b-button class="pull-right" type="submit" variant="primary">Salva</b-button>
              <b-button type="reset" variant="danger">Indietro</b-button>

            </b-form>
          </template>
        </KTPortlet>
        <!--end:: Widgets/Activity-->
      </div>

    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "../../../../store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import UserService from "../../../../common/user.service";
import ApiService from "../../../../common/api.service";
import Vue from "vue";

export default {
  data() {
    return {
      loading:false,
      formoptions:{
      },
      form: {
      },

    }
  },
  components: {
    KTPortlet
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Utenti", route: "/setting-organization/group/list" },
      { title: !this.isNew()?"Modifica subagenzia":'Nuova subagenzia' }
    ]);
  },

  created () {
    this.initialize()
  },
  methods:{
    isNew: function(){
      return !this.currentEditId();
    },
    currentEditId: function(){
      return this.$router.currentRoute.params.id;
    },
    onSubmit(evt) {
      evt.preventDefault()
      //alert(JSON.stringify(this.form))
      this.loading=true;
      let datasend = this.form;
      datasend.fistname = this.form.firstname;
      let params = {};
      // if(this.form.organization_id){
      //   params.organization_id = this.form.organization_id;
      // }
      params.organization_id= this.$store.getters.currentUser.organization_id;
      ApiService.post('groups'+(this.isNew()?'':'/'+this.currentEditId()),datasend,{ params: params})
          .then(({data}) => {
            if(this.isNew()){
              Vue.customNotifySuccess('Salvato');
              this.$router.push({ name: "setting-organization-group-edit", 'params':{'id':data.id} });
              this.loading = false;
              this.reload();
            }else {
              Vue.customNotifySuccess('Salvato');
              this.loading = false;
              this.reload();
            }

          })
          .catch(({response}) => {
            Vue.customNotifyError('Errore',response.data&&response.data.errors?response.data.errors.join(', '):'Si prega di riprovare');
          });
    },
    onReset(evt) {
      this.$router.go(-1);

    },
    initialize () {
      this.reload();
    },
    reload(){
      const that = this;
      if(!this.isNew()) {
        this.loading=true;
        ApiService.get('groups/'+this.currentEditId())
            .then(({data}) => {

              this.form = data;

              this.loading=false;
            })
            .catch(({response}) => {
              //context.commit(SET_ERROR, response.data.errors);
            });
      }
    },
  }
};
</script>
